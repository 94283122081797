// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-contact-us-jsx": () => import("./../src/pages/contact-us.jsx" /* webpackChunkName: "component---src-pages-contact-us-jsx" */),
  "component---src-pages-directory-jsx": () => import("./../src/pages/directory.jsx" /* webpackChunkName: "component---src-pages-directory-jsx" */),
  "component---src-pages-index-jsx": () => import("./../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-portal-jsx": () => import("./../src/pages/portal.jsx" /* webpackChunkName: "component---src-pages-portal-jsx" */),
  "component---src-pages-post-jsx": () => import("./../src/pages/post.jsx" /* webpackChunkName: "component---src-pages-post-jsx" */),
  "component---src-pages-welcome-jsx": () => import("./../src/pages/welcome.jsx" /* webpackChunkName: "component---src-pages-welcome-jsx" */)
}

