import * as firebase from 'firebase';
import axios from "axios"

// HELPER FUNCS
export const storeData = () => {
  return {
    postcode: localStorage.getItem("postcode") || "",
    idToken: localStorage.getItem("idToken") || "",
  }
}

export const getIdToken = async () => {
  const result = await axios.get("/.netlify/functions/createJWT");
  if (result.status === 200) {
    return result.data.idToken;
  } else {
    return "missing-token";
  }
};


// FIREBASE EVENT DEFS
export const lgPgChange = (prevLocation, location) => {
  firebase.analytics().logEvent('page_change', {
    ...storeData(),
    before: prevLocation ? prevLocation : "",
    after: location ? location : "",
  });
}

export const lgOutBndLink = (source, data = {}) => {
  firebase.analytics().logEvent("link_out", {
    ...storeData(),
    source: source,
    ...data
  })
}

export const lgBtnClick = (source, data = {}) => {
  firebase.analytics().logEvent("button_click", {
    ...storeData(),
    source: source,
    ...data
  });
}

export const lgSearch = (source, data = {}) => {
  firebase.analytics().logEvent("search", {
    ...storeData(),
    source: source,
    ...data
  });
}