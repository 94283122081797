import { lgPgChange, getIdToken } from "./src/common/firebaseAnalytics";
import * as firebase from "firebase";

// Logs when the client route changes
export const onRouteUpdate = ({ location, prevLocation }) => {
  lgPgChange(
    prevLocation ? prevLocation.href : "",
    location ? location.href : ""
  );
};

export const onClientEntry = () => {
  // Initialize Firebase
  var firebaseConfig = {
    // Non-secret
    apiKey: "AIzaSyAG6DzOM2P0--7UXjfPAETnri8XZ_yg1mM",
    authDomain: "takeitaway-a6047.firebaseapp.com",
    databaseURL: "https://takeitaway-a6047.firebaseio.com",
    projectId: "takeitaway-a6047",
    storageBucket: "takeitaway-a6047.appspot.com",
    messagingSenderId: "323302996118",
    appId: "1:323302996118:web:b3665dfffde950bb2f2ab7",
    measurementId: "G-JLCF05JZ0N",
  };
  firebase.initializeApp(firebaseConfig);
  firebase.analytics();

  // Check localstorage for flags
  // const welcomeCompleted = localStorage.getItem("welcomeCompleted");
  const idToken = localStorage.getItem("idToken");
  if (!idToken) {
    const setID = async () => {
      localStorage.setItem("idToken", getIdToken());
    };
    setID();
  }

  // Move user to welcome page if first time
  // if (!welcomeCompleted && !window.location.pathname.includes("/welcome")) {
  //   window.location.pathname = `/welcome`;
  // }
};
